import { SiTwitter } from 'react-icons/si';
import content from './content';

const TeamSection = () => {
  return (
    <div>
      <div className="text-center">
        <h1 className="text-xl px-5 py-2 bg-black text-white inline-block mb-10">
          Meet the team behind
        </h1>
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4 justify-center">
        {content.map(member => (
          <div key={member.id} className="mb-6 lg:mb-0">
            <img src={member.image} />
            <p className="mt-4 mb-2">{member.name}</p>
            <p className="mb-2 whitespace-pre-line">{member.description}</p>
            <div>
              <a href={member.twitter} className="text-2xl">
                <SiTwitter />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;

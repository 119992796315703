type PropsType = {
  children: React.ReactNode;
};

import './style.css';

const Button = ({ children, ...rest }: PropsType) => {
  return (
    <button className="btn" {...rest}>
      {children}
    </button>
  );
};

export default Button;

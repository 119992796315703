import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Mission from './pages/Mission';
import Plan from './pages/Plan';

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/plan" element={<Plan />} />
            </Routes>
          </ScrollToTop>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;

const Footer = () => {
  return (
    <footer>
      <div className="flex flex-wrap items-center justify-center w-100 px-4 md:px-8 py-7">
        <p>The GNOPUNKS are 10 000 NFTs living in the GNO blockchain.</p>
      </div>
    </footer>
  );
};

export default Footer;

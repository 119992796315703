import { NavLink } from 'react-router-dom';
import { SiTwitter, SiDiscord } from 'react-icons/si';
import logo from '../../images/logo.svg';
import Button from '../Button';

const Header = () => {
  return (
    <div className="w-100">
      <nav className="w-100 md:flex flex-wrap text-textGray items-center justify-center">
        <span className="md:flex-1">
          <NavLink to="/">
            <img src={logo} className="mb-10 md:mb-0" />
          </NavLink>
        </span>
        <ul className="w-100 h-100 flex mb-8 md:mb-0 flex-wrap md:items-center justify-center text-xl">
          <li className="mr-7 md:mb-0">
            <NavLink
              className={({ isActive }) => (isActive ? 'underline' : '')}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="mr-7 md:mb-0">
            <NavLink
              className={({ isActive }) => (isActive ? 'underline' : '')}
              to="/mission"
            >
              Mission
            </NavLink>
          </li>
          <li className="md:mb-0">
            <NavLink
              className={({ isActive }) => (isActive ? 'underline' : '')}
              to="/plan"
            >
              Plan
            </NavLink>
          </li>
        </ul>
        <div className="flex md:flex-1 justify-center md:justify-end items-center">
          <a
            href="#"
            target="_blank"
            rel="noreferrer"
            className="mr-8 text-2xl"
          >
            <SiTwitter />
          </a>
          <a
            href="#"
            target="_blank"
            rel="noreferrer"
            className="mr-8 text-2xl"
          >
            <SiDiscord />
          </a>

          <Button>Connect Wallet</Button>
        </div>
      </nav>
    </div>
  );
};

export default Header;

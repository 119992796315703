const WhiteBox = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`bg-white p-10 md:p-16 shadow-custom w-100 ${
        className && className
      }`}
    >
      {children}
    </div>
  );
};

export default WhiteBox;

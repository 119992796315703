import { Helmet } from 'react-helmet';
import { SiDiscord } from 'react-icons/si';
import WhiteBox from '../components/WhiteBox';

const Mission = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mission - GNOPUNKS</title>
        <meta name="description" content="GNOPUNKS website." />
      </Helmet>

      <div className="flex items-center justify-center my-24 text-center animate__animated animate__slideInUp">
        <WhiteBox className="max-w-[889px]">
          <h1 className="text-xl px-5 py-2 bg-black text-white inline-block mb-10">
            The GNOPUNKS Manifesto
          </h1>
          <p className="mb-8">
            After millennia of prosperity, greed and decadence have rapidly
            transformed our humanity and reversed most values.
          </p>
          <p className="mb-8">
            Led by false idols, this upside down world only manages to increase
            inequalities and tensions.
          </p>
          <p className="mb-8">
            The technological revolution that was supposed to facilitate the
            daily life of our civilization has unfortunately only served to
            reinforce the control of the powerful.
          </p>
          <p className="mb-8">
            Aware of this disaster, a group of brave gnomies punks decide to
            leave the ship while it is still possible to rebuild a new
            human-centric system.
          </p>
          <p className="mb-8">
            This is how this small community settles on the mysterious planet
            GNO to build the future.
          </p>

          <div className="flex items-center justify-center">
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
              className="text-[40px]"
            >
              <SiDiscord />
            </a>
          </div>
        </WhiteBox>
      </div>
    </>
  );
};

export default Mission;

import team1 from '../../images/team/team-1.png';
import team2 from '../../images/team/team-2.png';
import team3 from '../../images/team/team-3.png';
import team4 from '../../images/team/team-4.png';

const content = [
  {
    id: 0,
    name: '***',
    description: 'GNOPUNKS leader\n & mastermind',
    image: team1,
    twitter: '#',
  },
  {
    id: 1,
    name: '***',
    description: 'Marketing wizard\n& community lead',
    image: team2,
    twitter: '#',
  },
  {
    id: 2,
    name: '***',
    description: 'Tech genius &\nGNO enthusiast',
    image: team3,
    twitter: '#',
  },
  {
    id: 3,
    name: '***',
    description: 'Discord expert\n& gatekeeper',
    image: team4,
    twitter: '#',
  },
];

export default content;

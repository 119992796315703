import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import nft1 from '../images/nft-1.png';
import nft2 from '../images/nft-2.png';
import nft3 from '../images/nft-3.png';

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GNOPUNKS</title>
        <meta name="description" content="GNOPUNKS website." />
      </Helmet>

      <div className="w-100 overflow-hidden h-[85vh] justify-center items-center flex flex-col relative">
        <img
          className="absolute -z-10 -left-1/3 md:left-[10%] 2xl:left-1/4 animate__animated animate__bounceInLeft"
          src={nft1}
        />
        <img
          className="absolute -z-10 md:left-2/3 top-10 animate__animated animate__bounceInDown"
          src={nft2}
        />
        <img
          className="absolute -z-10 left-3/4 top-2/4 animate__animated animate__bounceInRight"
          src={nft3}
        />
        <h1 className="text-center bg-black text-white inline-block px-5 py-2 mb-2 animate__animated animate__bounceInDown">
          Building
        </h1>
        <h1 className="text-center bg-black text-white inline-block px-5 py-2 mb-2 animate__animated animate__bounceInDown">
          another
        </h1>
        <h1 className="text-center bg-black text-white inline-block px-5 py-2 mb-6 animate__animated animate__bounceInDown">
          future
        </h1>

        <Button>Join us now</Button>
      </div>
    </>
  );
}

export default Home;

import { Helmet } from 'react-helmet';
import TeamSection from '../components/TeamSection';
import WhiteBox from '../components/WhiteBox';

const Plan = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Plan - GNOPUNKS</title>
        <meta name="description" content="GNOPUNKS website." />
      </Helmet>

      <div className="flex items-center justify-center my-24 animate__animated animate__slideInUp">
        <WhiteBox className="max-w-[1189px]">
          <div className="text-center">
            <h1 className="text-xl px-5 py-2 bg-black text-white inline-block mb-10 text-center">
              Our plan to build on GNO
            </h1>
          </div>
          <p>1. GNOPUNKS NFT collection mint on the *** Launchpad.</p>
          <p>2. GNOPUNKS DAO setup.</p>
          <p>3. *** NFT marketplace launch.</p>
          <p>4. GNOPUNKS Validator staking.</p>
          <p>5. Discord gateway for GNO NFT holders..</p>
          <p>6. DAO votes on the next projects to be launched.</p>
          <p className="mb-24">To be continued...</p>

          <TeamSection />
        </WhiteBox>
      </div>
    </>
  );
};

export default Plan;
